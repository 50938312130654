import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import appConfig from './appconfig.json'

const { persistAtom } = recoilPersist();

// 다국어, 한국어가 기본
export const Language = atom({
    key : 'Language',
    default : 'ko',
    effects_UNSTABLE: [persistAtom]
})

// 로그인, 인증 관련 주소
export const accessLink = atom({
    key: 'accessLink',
    default: `${appConfig?.apiUrl}/api/auth/`,
    effects_UNSTABLE: [persistAtom]
})

export const Customers = atom({
    key: 'Customers',
    default: [],
    effects_UNSTABLE: [persistAtom]
})

// 로그인 성공 여부
export const loginSuccess = atom({
    key: 'loginSuccess',
    default: false,
    effects_UNSTABLE: [persistAtom]
})

// 로그인 후 user id 받기
export const PKuserId = atom({
    key: 'PKuserId',
    default: '',
    effects_UNSTABLE: [persistAtom]
})

// 로그인한 사람 이름
export const userName = atom({
    key: 'userName',
    default: '',
    effects_UNSTABLE: [persistAtom]
})
// 로그인한 사람 이메일
export const userEmail = atom({
    key: 'userEmail',
    default: '',
    effects_UNSTABLE: [persistAtom]
})

// access 토큰
export const accessToken = atom({
    key: 'accessToken',
    default: '',
    effects_UNSTABLE: [persistAtom]
})  
// refresh 토큰
export const refreshToken = atom({
    key: 'refreshToken',
    default: '',
    effects_UNSTABLE: [persistAtom]
})  

// api 주소
export const apiLink = atom({
    key : 'apiLink',
    default : `${appConfig?.apiUrl}/api/v1/`
})
// ws 주소
export const wsLink = atom({
    key : 'wsLink',
    default : `${appConfig?.wsUrl}`
})

// 전역 관리자
export const gloabalManager = atom({
    key: 'gloabalManager',
    default: false,
    effects_UNSTABLE: [persistAtom]
})
// 고객 관리자
export const customerManager = atom({
    key: 'customerManager',
    default: false,
    effects_UNSTABLE: [persistAtom]
})

// 고객 id
export const customerId = atom({
    key: 'customerId',
    default: '',
    effects_UNSTABLE: [persistAtom]
})
// 고객 이름
export const customerName = atom({
    key: 'customerName',
    default: '',
    effects_UNSTABLE: [persistAtom]
})


//관리화면-----------------------------------------
// 드롭다운에서 선택된 고객사
export const selectedCustomer = atom({
    key: 'selectedCustomer',
    default: '',
    effects_UNSTABLE: [persistAtom]
})

// fetch 요청 상태 공유
export const fetchStatusState = atom({
    key: 'fetchStatusState',
    default: {}
})

// txplatform 열람 자격 있는지 확인
export const useDSP = atom({
    key: 'useDSP',
    default: false,
    effects_UNSTABLE: [persistAtom]
})