import React, { useEffect } from "react";
// import { ErrorBoundary } from 'react-error-boundary'
import { Routes, Route } from "react-router-dom";

import LogIn from "./pages/login"
import SelectSys from './pages/selectSys'
import PrivateRoute from './private'

// 디자인 적용
import "primereact/resources/themes/lara-light-purple/theme.css"
import "./asset/css/custom.css"
import "./asset/css/custom.min.css"
import "./asset/css/primereact.min.css"
import "./asset/css/tw-css.css"
import "./asset/css/layout.css"

function App() {
  
  // load error 예외처리
  // const ErrorFallback = ({ error }) => {
  //   useEffect(() => {
  //     const loadFailedMessage = /Load failed/

  //     // prop으로 받은 error 확인해서 에러 발생 시 새로고침
  //     if (error?.message) {
  //       if (loadFailedMessage.test(error.message)) {
  //         // window.lacation.reload()
  //         console.log('error.message : ', error.message)
  //         console.log('error : ', error)
  //       }
  //     }
  //   }, [error])
  //   return <div>Something went wrong... </div>
  // }
  
  return (
    <>
      {/* <ErrorBoundary FallbackComponent={ErrorFallback}> */}
        <Routes>
          {/* PrivateRoute를 사용할 때는 element를 전달 */}
          <Route path="*" element={<SelectSys/>} />
          <Route path="/login" element={<LogIn />} />
          
          <Route
            path='/'
            element={<PrivateRoute />} // PrivateRoute에 element를 전달
          >
            <Route path="/SelectSys" element={<SelectSys />} />
            <Route path="/" element={<SelectSys />} />        
          </Route>
        </Routes>
      {/* </ErrorBoundary> */}
    </>  
  )
}
export default App
