import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { loginSuccess } from './atom';

const PrivateRoute = ({ element, ...props }) => {
  const isLoggedIn = useRecoilValue(loginSuccess)

  if (isLoggedIn && props.path === '/') {    
    // 로그인된 상태이고, '/' 경로인 경우에는 다른 페이지로 리디렉션
    // alert(1)
    return <Navigate to="/SelectSys" replace />
  }

  if (!isLoggedIn && props.path !== '/login') {    
    // 로그인되지 않은 상태에서 '/' 이외의 경로로의 접근을 차단
    return <Navigate to="/login" replace />
  }  

  // 모든 경우에는 Outlet 반환
  return <Outlet />
}

export default PrivateRoute
