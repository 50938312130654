import React, { useEffect } from 'react';
import { useNavigate, Navigate } from 'react-router-dom'

// 리코일
import { useRecoilState, useRecoilCallback } from 'recoil'
import { loginSuccess } from '../atom'
import { apiLink } from '../atom'
import { accessToken } from '../atom'

import { customerId } from '../atom'
import { PKuserId } from '../atom'

import { gloabalManager, customerManager } from '../atom'

import { useDSP } from '../atom'

import 'primeicons/primeicons.css'

import { useTranslation } from "react-i18next";
// import i18n, { setLanguage } from "../locales/i18n"

// 토큰 다시 받기
import { useAuthFetch } from '../hooks/useAuthFetch'

import * as RecoilStates from '../atom';


export default function SelectSys() {
  // 다국어 처리
  const { t } = useTranslation();
  // 언어 변경
  // const changeLanguage = (lang) => {
  //   setLanguage(lang)
  //     i18n.changeLanguage(lang);
  // }

  const [apilink] = useRecoilState(apiLink)
  // 토큰 두개
  const [accesstoken] = useRecoilState(accessToken)
  const getRequestOption = {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': accesstoken
    }
  }

  // 새 토큰 받기
  const fetchAuth = useAuthFetch()

  // 모든 리코일 변수 초기화, 로그아웃
  const movePage = useNavigate();
  const resetAllStates = useRecoilCallback(({ reset }) => () => {
    // 각 Recoil 상태의 초기값을 이용하여 초기화
    Object.keys(RecoilStates).forEach((key) => {
      const initialState = RecoilStates[key].default;
      reset(RecoilStates[key], initialState);
    });
  });
  // 모든 Recoil 상태를 초기화하는 핸들러 함수
  const logoutClick = () => {
    resetAllStates();
    movePage('/login')
  };

  // 사용자 권한 확인하기
  const [gloabalmanager, setglobalmanager] = useRecoilState(gloabalManager)
  const [customermanager, setcustomermanager] = useRecoilState(customerManager)
  const [pk] = useRecoilState(PKuserId)
  // 고객사, 유저 정보들
  const [customerid, setcustomerid] = useRecoilState(customerId)

  useEffect(() => {
    const pkUrl = `http://${apilink}users/${pk}/`
    fetchAuth(pkUrl, getRequestOption)
      .then((response) => {
        return response?.clone()?.json()
      })
      .then((json) => {
        setglobalmanager(json?.is_staff)
        setcustomermanager(json?.is_admin)
        setcustomerid(json?.customer)    
      })
  }, [apilink, pk])  

  // 전역관리자면 고객사 관련 정보 안 불러오는 걸로
  useEffect(() => {
    if (gloabalmanager === false && customerid !== null) {
      fetchCustInfo()
    }
    if (gloabalmanager === true) {
      setusedsp(true)
    }
  }, [gloabalmanager, customerid])

  // txplatform 열람 자격 있는지 저장
  const [usedsp, setusedsp] = useRecoilState(useDSP)
  
  const fetchCustInfo = () => {
    if (!gloabalmanager && customerid) {
      const fetchCustomerUrl = `http://${apilink}customers/${customerid}/`
      fetchAuth(fetchCustomerUrl, getRequestOption)
      .then((response) => {
        return response?.clone()?.json()
      })
      .then((json) => {
        setusedsp(json?.txplatformuse)
      })
    }    
  }

  const subSysSelect = (selectedSys) => {
    switch(selectedSys) {
      case t("sys1"):
        openNewTab('/sensormonitoring')
        break
      case t("sys2"):
        openNewTab('/alertsystem')
        break
      case t("sys3"):
        openNewTab('/txplatform')
        break
      default:
        break;
    }
  }

  const openNewTab = (url) => {
    window.location.href = url
  }

  const [isLoggedIn] = useRecoilState(loginSuccess)

  return (   
    <> 
    {isLoggedIn
      ?      
      <>
      <Navigate to="/SelectSys" replace />
      <div className="bg-black h-screen flex flex-col items-center justify-center">

      <h1 className="text-gray-200 text-5xl">Select Sub System</h1>

      {/* 서브시스템 */}
      <div className="flex flex-row flex-wrap gap-5 mt-8">
        
          {/* 센싱데이터 모니터링 */}
          <div onClick={() => subSysSelect(t("sys1"))} className="w-52 h-48 card rounded border-2 border-transparent bg-gray-300 flex items-center justify-center hover:bg-gray-400 cursor-pointer">
            <span className=" font-black text-gray-800 align-text-bottom"> {t("sys1")} </span>
          </div>
          {/* 경보발령 시스템 */}
          <div onClick={() => subSysSelect(t("sys2"))} className="w-52 h-48 card rounded border-2 border-transparent bg-gray-300 flex items-center justify-center hover:bg-gray-400 cursor-pointer">
            <span className=" font-black text-gray-800 align-text-bottom"> {t("sys2")} </span>
          </div>
          {usedsp
          ?
          <div onClick={() => subSysSelect(t("sys3"))} className="w-52 h-48 card rounded border-2 border-transparent bg-gray-300 flex items-center justify-center hover:bg-gray-400 cursor-pointer">
            <span className=" font-black text-gray-800 align-text-bottom"> {t("sys3")} </span>
          </div>
          :
          null
          }
          {/* 데이터공유 플랫폼 */}
                  
      </div>
      {/* 로그아웃 버튼 */}
      <button onClick={logoutClick} className="border-2 border-gray-400 text-gray-400 px-4 py-1 mt-20 hover:border-gray-400 hover:text-gray-400">
          {t('logout')}
      </button>
      </div>
      </>
      :
      <Navigate to="/login" replace />
    }
    </>
  )
}
