// import i18n from "i18next"
// import { initReactI18next } from "react-i18next"

// import TranslationEn from "./eng.json"
// import TranslationKo from "./kor.json"

//     const resource = {
//         en: {
//             translations: TranslationEn
//         },
//         ko: {
//             translations: TranslationKo
//         }
//     }

//     i18n
//     .use(initReactI18next)
//     .init({
//         resources: resource,
//         lng: localStorage.getItem('language') || 'ko', // 로컬 스토리지에서 언어 설정을 가져와 사용합니다. 없으면 기본값인 'ko'를 사용합니다.
//         fallbackLng: 'ko',
//         debug: true,
//         defaultNS: 'translations',
//         ns: 'translations',
//         keySeparator: false,
//         interpolation: {
//             escapeValue: false
//         }
//     })

// // 언어 변경 시 로컬 스토리지에 저장하는 함수
// export const setLanguage = (language) => {
//     localStorage.setItem('language', language)
// }

// export default i18n

import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import TranslationEn from "./eng.json"
import TranslationKo from "./kor.json"

const resource = {
    en: {
        translations: TranslationEn
    },
    ko: {
        translations: TranslationKo
    }
}

// 로컬 스토리지에서 언어 설정을 가져오고 없으면 'ko'로 설정합니다.
const savedLanguage = localStorage.getItem('language')

i18n
    .use(initReactI18next)
    .init({
        resources: resource,
        lng: savedLanguage,
        fallbackLng: savedLanguage,
        debug: true,
        defaultNS: 'translations',
        ns: 'translations',
        keySeparator: false,
        interpolation: {
            escapeValue: false
        }
    })

// 언어 변경 시 로컬 스토리지에 저장하는 함수
export const setLanguage = (language) => {
    i18n.changeLanguage(language) // i18n에서 언어 변경
    localStorage.setItem('language', language) // 로컬 스토리지에 언어 설정 저장
}

export default i18n

