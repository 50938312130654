import React, { useState, useEffect } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from "react-i18next";
import { InputText } from 'primereact/inputtext'
import { Password } from 'primereact/password'

import { useRecoilState } from 'recoil'
import { accessLink }from '../atom'

import { loginSuccess } from '../atom'
import { accessToken } from '../atom'
import { refreshToken } from '../atom'
import { PKuserId } from '../atom'
import { userName } from '../atom'

import i18n, { setLanguage } from "../locales/i18n"

export default function LogIn() {
  // 다국어 처리
  const { t } = useTranslation();
  // 언어 변경하기
  const changeLanguage = (lang) => {
    setLanguage(lang)
    i18n.changeLanguage(lang)
  }

  // api 로그인 링크
  const [accesslink] = useRecoilState(accessLink)
  // 로그인 성공 여부
  const [loginsuccess, setloginsuccess] = useRecoilState(loginSuccess)
  // pk(user id) 
  const [pk, setpk] = useRecoilState(PKuserId)
  // 유저 이름
  const [username, setusername] = useRecoilState(userName)

  // access 토큰 
  // eslint-disable-next-line
  const [accesstoken, setaccesstoken] = useRecoilState(accessToken)
  // refresh 토큰
  // eslint-disable-next-line
  const [refreshtoken, setrefreshtoken] = useRecoilState(refreshToken)
  // 로그인 버튼 클릭 시 이동
  const movePage = useNavigate();

  const [loginErr, setLoginErr] = useState(false)

  // 로그인
  const logIn = async () => {  
    try {
      if ( !id || !pw) {
        setLoginErr(true)
      }
      const loginApi = `http://${accesslink}login/`

      // Option 요청 처리
      const optionsResponse = await fetch(loginApi, {
        method: 'OPTIONS',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      // Option 요청이 성공하면 본래의 POST 요청을 보냄
      if (optionsResponse.ok) {
        const response = await fetch(loginApi, {
          method: 'POST',        
          mode: 'cors', // no-cors, cors, *same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, *same-origin, omit
          redirect: 'follow', // manual, *follow, error
          referrer: 'no-referrer', // no-referrer, *client
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: id.toString(),
            password: pw.toString()
          })
        })

        if (response.ok) {
          const token = await response.json()
          setloginsuccess(true)
          setaccesstoken(`Bearer ${token?.access}`)
          setrefreshtoken(token?.refresh)
          setpk(token?.user?.pk)
          setusername(token?.user?.username)
          movePage("/SelectSys")
        } else {
          setLoginErr(true)
        }
      } else {
        console.log('Option 요청 실패')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleOnKeyPress = e => {
    if (e.key === 'Enter') {
      logIn(); // Enter 입력이 되면 클릭 이벤트 실행
    }
  };
  
  // 드롭다운 목록
  const language = [
    { name: t('kor'), code: 'ko' },
    { name: t('eng'), code: 'en' }
  ];
  const [selectedLanguage, setSelectedLanguage] = useState(language[1])
  // 기존에 설정한 언어 있는지 탐색
  const savedLanguage = localStorage.getItem('language')
  useEffect(() => {  
    if (savedLanguage === language?.[0]?.code) {
      setSelectedLanguage(language[0])
    }

    if (savedLanguage === language?.[1]?.code) {
      setSelectedLanguage(language[1])
    }
    
    // 기존에 설정한 언어 없을 떄 (기본설정)
    if (!savedLanguage) {
      // 사용자 브라우저 언어 확인
      let locale = navigator.language || navigator.userLanguage
      
      switch (locale) {
        case 'ko':
        case 'ko-KR':
          changeLanguage(language?.[0]?.code)
          setSelectedLanguage(language?.[0])
          break;
      
        default:
          changeLanguage(language?.[1]?.code)
          setSelectedLanguage(language?.[1])
          break;
      }
    }    
  }, [savedLanguage])

  const [id, setId] = useState("")
  const [pw, setPw] = useState("")

  return (
    <>
    {loginsuccess
    ?
      <Navigate to="/SelectSys" replace/>
    :
    <>
      <div className="layout-wrapper layout-static">
        {/* layout-topbar */}
        <div className="layout-topbar">
          <a className="layout-topbar-logo w-auto" href="/">
            <div className="font-bolder text-xl">
              <span className="text-primary">Analyzing and monitoring </span>
              <span className="text-900"> Sensing data</span>
            </div>
          </a>
        </div>
        <div className="flex align-items-center justify-content-center min-h-screen min-w-full overflow-hidden surface-300 pt-8">
          <div className="flex flex-column align-items-center justify-content-center">
            <div className="w-full surface-card py-6 px-6 md:px-7 border-round-2xl">
              <div className="text-center mb-3">
                <div className="font-bolder text-2xl mb-2">
                  <span className="text-primary">Analyzing and monitoring</span>
                  <span className="text-900"> Sensing data</span>
                </div>
                <span className="text-700 text-3xl font-bold">센싱데이터 분석 및 모니터링</span>
              </div>
              <div>
                <label
                  htmlFor="id"
                  className="block text-900 text-lg font-medium mb-2"
                >
                  {t("id")}
                </label>
                <InputText 
                  value={id} 
                  onChange={(e) => setId(e.target.value)} 
                  // className="p-inputtext p-component text-xl border w-full md:w-24rem p-2.5 mb-4"
                  className="w-full"
                />
                
                <label
                  htmlFor="password1"
                  className="block text-900 font-medium text-xl mb-2"
                >
                  {t("pw")}
                </label>
                <Password 
                  value={pw} 
                  onChange={(e) => setPw(e.target.value)} 
                  onKeyPress={handleOnKeyPress} 
                  // className="p-inputtext p-component p-password-input text-xl border w-full md:w-24rem p-2.5 mb-4" 
                  className="w-full"
                  inputStyle={{width: "100%"}} 
                  feedback={false} 
                  toggleMask 
                />
                {loginErr ? 
                  <p class="text-red-400 w-full">{t('loginErr')}</p>
                :
                  null
                }
                <label
                  htmlFor="password1"
                  className="block text-900 font-medium text-xl mb-2 mt-4"
                >
                  Language
                </label>
                <Dropdown value={selectedLanguage} onChange={(e) => 
                  {
                    setSelectedLanguage(e.value) 
                    changeLanguage(e.value.code);
                  }} 
                  options={language} optionLabel='name'
                  placeholder={t('kor')} 
                  className="p-dropdown p-component p-inputwrapper w-full text-lg "
                />
                
                <div className="mt-5">
                  <button
                    aria-label="Sign In"
                    className="w-full p-2.5 text-lg p-button p-component"
                    data-pc-name="button"
                    data-pc-section="root"
                    onClick={logIn}
                  >
                    <span className="p-button-label p-c" data-pc-section="label">
                    {t("login")}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <footer>
        &copy; Tailwind, PrimeReact
      </footer>
    </>
    }
    </>
  
  )
}
