import { useRef } from 'react'
import { useRecoilState } from 'recoil'
import { accessLink, accessToken, refreshToken, fetchStatusState } from '../atom'

export const useAuthFetch = () => {
    const [accesslink] = useRecoilState(accessLink)
    const [accesstoken, setaccesstoken] = useRecoilState(accessToken)
    const [refreshtoken, setrefreshtoken] = useRecoilState(refreshToken)
    const [fetchStatus, setfetchStatus] = useRecoilState(fetchStatusState)

    // 토큰 갱신중인 지 확인 ref
    const isRefreshing = useRef(false)
    // 대기중인 요청들 저장
    const refreshSub = useRef([])

    // 새 토큰 발급
    const newToken = () => {
        return new Promise((resolve, reject) => {
            if (isRefreshing.current) {
                // 이미 토큰 갱신중이면 대기 목록에 추가
                refreshSub.current.push({resolve, reject})
            }
            else {
                // 토큰 갱신 받기
                isRefreshing.current = true
                const refreshUrl = `http://${accesslink}token/refresh/`

                // 서버에 토큰 요청
                fetch(refreshUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({refresh: refreshtoken})
                })
                .then(response => {
                    // refreshToken이 만료되었거나 이상한 값이 들어가서 오류 발생 시
                    if ((response.status === 401 || response.status === 403)) {
                        localStorage.clear()
                        window.location.href = '/'
                    }
                    return response.json()
                })
                .then(json => {
                    // 새 토큰 들 recoil에 저장
                    setrefreshtoken(json?.refresh)
                    setaccesstoken(`Bearer ${json?.access}`)

                    // 대기중인 요청들 모두 해결
                    refreshSub.current?.forEach(sub => sub.resolve(`Bearer ${json?.access}`))
                    refreshSub.current = []
                    resolve(`Bearer ${json?.access}`)
                    isRefreshing.current = false
                })
                .catch(error => {
                    // 에러 발생 시, 대기중인 요청들에 에러 넣기
                    isRefreshing.current = false
                    refreshSub.current?.forEach(sub => sub.reject(error))
                    refreshSub.current = []
                    reject(error)
                    console.log('Token Promise Error : ', error)
                })
            }
        })
    }

    // 토큰 갱신 포함된 fetch 함수
    const fetchAuth = (url, fetchOption) => {
        // 이미 동일한 url 있는지 검사
        if (fetchStatus[url]) {
            return fetchStatus[url]
        }

        const fetchPromise = new Promise((resolve, reject) => {
            // 요청 헤더에 토큰 추가해주기
            fetchOption.headers = {
                ...fetchOption.headers,
                'Authorization': accesstoken
            }

            // fetch
            fetch(url, fetchOption)
            .then(response => {
                if (response.status === 403) {
                    // 토큰 말료 시, 새 토큰 요청 후 시도
                    return newToken().then(newAccessToken => {
                        if (newAccessToken) {
                            fetchOption.headers['Authorization'] = newAccessToken
                            return fetch(url, fetchOption)
                                    .then(newResponse => {
                                        resolve(newResponse)
                                        // fetch 상태 업데이트
                                        // prevStatus에서 url에 해당하는 키 제거하고 나머지 키, 값 쌍을 rest에 반환
                                        setfetchStatus(prevStatus => {
                                            const { [url]: _, ...rest } = prevStatus
                                            return rest
                                        })
                                        return newResponse
                                    })
                        }
                    })
                    .catch(error => {
                        // 새 토큰 요청 중 에러 발생
                        reject(error)
                        setfetchStatus(prevStatus => {
                            const { [url]: _, ...rest } = prevStatus
                            return rest
                        })
                        console.log('fetchAuth Err : ', error)
                    })
                }
                else {
                    // 토큰 만료가 아닌 경우, 결과 넘기기
                    resolve(response)
                    setfetchStatus(prevStatus => {
                        const { [url]: _, ...rest } = prevStatus
                        return rest
                    })
                }
            })
            .catch(error => {
                // fetch 요청 중 에러 발생
                reject(error)
                setfetchStatus(prevStatus => {
                    const { [url]: _, ...rest } = prevStatus
                    return rest
                })
                console.log('fetchAuth Err : ', error)
            })
        })

        // 현재 url에 대한 fetch 상태 업데이트
        setfetchStatus(prevStatus => ({
            ...prevStatus,
            [url]: fetchPromise
        }))
        return fetchPromise
    }
    return fetchAuth
}